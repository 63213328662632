* {
  font-family: "VietnamPro", Arial, sans-serif;
}

.resp-image {
  width: 200px;
}
.resp-width {
  width: 100% !important;
}
.body-text {
  width: 100% !important;
}
.resp-navbar {
  width: 100% !important;
}
.title-text {
  font-size: 30px;
}
.body-text {
  font-size: 20px;
}
.footer-menu {
  width: 80% !important;
}

.hover:hover {
  background-color: #eef1f5;
}

@media (min-width: 768px) {
  .resp-width {
    width: 80% !important;
  }
  .body-text {
    width: 80% !important;
  }
  .resp-image {
    width: 300px;
  }
  .resp-navbar {
    width: 100% !important;
  }
  .title-text {
    font-size: 38px;
  }
  .body-text {
    font-size: 24px;
  }
  .footer-menu {
    width: 60% !important;
  }
}

@media (min-width: 992px) {
  .resp-width {
    width: 61% !important;
  }
  .body-text {
    width: 65% !important;
  }
  .resp-image {
    width: 300px;
  }
  .resp-navbar {
    width: 75% !important;
  }
  .title-text {
    font-size: 38px;
  }
  .body-text {
    font-size: 24px;
  }
  .footer-menu {
    width: 30% !important;
  }
}

.container-nav {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 150px;
  left: 0;
  margin: 0 auto;
}
.container-nav p {
  font-size: 20px;
}
.container-nav a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #ff5252;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  top: 5px;
}
.container-nav a:after {
  content: "";
  position: absolute;
  background: #ff5252;
  height: 2px;
  width: 0%;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  transition: 0.35s ease;
}
.container-nav a:hover:after,
.container-nav a:focus:after,
.container-nav a:active:after {
  width: 100%;
}

h1 {
  position: relative;
  text-align: center;
  font-family: "Vollkorn", serif;
}

.button_container {
  position: fixed;
  top: 50px;
  right: 10%;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
  border: none !important;
}
.button_container:hover {
  opacity: 0.7;
}
.button_container.active .top {
  transform: translateY(9px) translateX(0) rotate(45deg);
  background: #000000;
}
.button_container.active .middle {
  opacity: 0;
  background: #000000;
}
.button_container.active .bottom {
  transform: translateY(-9px) translateX(0) rotate(-45deg);
  background: #000000;
}
.button_container span {
  background: #000000;
  border: none;
  height: 3px;
  width: 90%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 9px;
}
.button_container span:nth-of-type(3) {
  top: 18px;
}

.overlay {
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 0.9;
  visibility: visible;
  height: 100%;
  z-index: 200;
}
.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.overlay.open li:nth-of-type(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.overlay nav {
  position: relative;
  height: 40%;
  top: 50%;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  transform: translateY(-50%);
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  min-height: 50px;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  display: block;
  position: relative;
  color: #000000;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
  width: 100%;
}
.overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #fff;
  transition: 0.35s;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
